import Api from '@/services/Api'

export default {
  findAll (param) {
    return Api().post('enderecos', param)
  },
  insert (endereco) {
    return Api().post('endereco', endereco)
  },
  update (endereco) {
    return Api().put('endereco', endereco)
  },
  delete (endereco) {
    return Api().delete(`endereco?id=${endereco.id}`, endereco)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().get('enderecoParam', param)
  }
}
